<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="activityCatId"
                        label="活动分类"
                        label-width="12em"
                    >
                        <CascaderPicker
                            clearable
                            filterable
                            :api-class="selectApi"
                            v-model="formModel.activityCatId"
                            :query-params="cascaderPickerQueryParams"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="orgName"
                        label="组织机构"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.orgName"
                            placeholder="请输入组织机构"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityName"
                        label="活动名称"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.activityName"
                            placeholder="请输入活动名称"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityImage"
                        label="活动图片"
                        label-width="12em"
                    >
                        <upload
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="10"
                            v-model="formModel.activityImage"
                            tips=""
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item
                        prop="activityDesc"
                        label="活动描述"
                        label-width="12em"
                    >
                        <Tinymce
                            :height="300"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.activityDesc"
                            image-upload-tips="图片大小不能超过5M，格式支持jpg、png、bmp"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityAttachment"
                        label="活动附件"
                        label-width="12em"
                    >
                        <upload
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="10"
                            v-model="formModel.activityAttachment"
                            tips=""
                        />
                    </el-form-item>
                    <el-form-item
                        prop="releaseTime"
                        label="发布时间"
                        label-width="12em"
                    >
                        <el-date-picker
                            v-model="formModel.releaseTime"
                            type="datetime"
                            placeholder="请选择发布时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="applyEndTime"
                        label="报名截止时间"
                        label-width="12em"
                    >
                        <el-date-picker
                            v-model="formModel.applyEndTime"
                            type="datetime"
                            placeholder="请选择报名截止时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityTime"
                        label="活动时间"
                        label-width="12em"
                    >
                        <el-date-picker
                            :picker-options="$utils.CommonConfig.pickerOptions"
                            v-model="formModel.activityTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="活动开始时间"
                            end-placeholder="活动结束时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="limitPeopleNum"
                        label="限制人数"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            v-model="formModel.limitPeopleNum"
                            :min="0"
                        />
                    </el-form-item>
                    <!--                    <el-form-item
                        prop="limitPointNum"
                        label="限制积分"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            v-model="formModel.limitPointNum"
                            :min="0"
                        />
                    </el-form-item>-->
                    <el-form-item
                        prop="activityAmount"
                        label="活动金额"
                        label-width="12em"
                    >
                        <el-input-number
                            controls-position="right"
                            v-model="formModel.activityAmount"
                            :min="0"
                            :percision="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="regionId"
                        label="活动地区"
                        label-width="12em"
                    >
                        <CascaderPicker
                            clearable
                            filterable
                            :api-class="regionApi"
                            v-model="formModel.regionId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="address"
                        label="活动详细地址"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.address"
                            placeholder="请输入活动详细地址"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="latLng"
                        label="活动地址坐标"
                        label-width="12em"
                    >
                        <LatLngPicker v-model="formModel.latLng "/>
                    </el-form-item>
                    <el-form-item
                        prop="contactFullName"
                        label="联系人姓名"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.contactFullName"
                            placeholder="请输入联系人姓名"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="contactTel"
                        label="联系人电话"
                        label-width="12em"
                    >
                        <el-input
                            v-model="formModel.contactTel"
                            placeholder="请输入联系人电话"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isNeedSignIn"
                        label="是否需要签到"
                        label-width="12em"
                    >
                        <el-switch
                            v-model="formModel.isNeedSignIn"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        label-width="12em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm(false)"
                        >
                            保 存
                        </el-button>
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm(true)"
                        >
                            保存并提交
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
export default {
    name: 'ActivityEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                activityCatId: '',
                orgName: '',
                activityType: 2,
                activityName: '',
                activityImage: [],
                activityDesc: '',
                activityAttachment: [],
                releaseTime: '',
                applyEndTime: '',
                activityTime: '',
                limitPeopleNum: 0,
                limitPointNum: 0,
                activityAmount: 0,
                regionId: '',
                address: '',
                latLng: '',
                contactFullName: '',
                contactTel: '',
                isNeedSignIn: false,
            },
            // 表单校验规则
            formRules: {
                activityCatId: {
                    required: true,
                    message: '请选择',
                },
                orgName: {
                    required: true,
                    message: '请输入',
                },
                activityType: {
                    required: true,
                    message: '请选择',
                },
                activityName: {
                    required: true,
                    message: '请输入',
                },
                activityImage: {
                    required: true,
                    message: '请上传',
                },
                releaseTime: {
                    required: true,
                    message: '请输入',
                },
                applyEndTime: {
                    required: true,
                    message: '请输入',
                },
                activityTime: {
                    required: true,
                    message: '请输入',
                },
                contactFullName: {
                    required: true,
                    message: '请输入',
                },
                contactTel: {
                    required: true,
                    message: '请输入',
                },
                isNeedSignIn: {
                    required: true,
                    message: '请选择',
                },
                regionId: {
                    required: true,
                    message: '请选择',
                },
                address: {
                    required: true,
                    message: '请输入',
                },
                latLng: {
                    required: true,
                    message: '请输入',
                },
            },
            selectApi: this.$api.Mb.ActivityCat,
            orgApi: this.$api.Rs.Org,
            regionApi: this.$api.Rs.Region,
            TreeData: [],
        };
    },
    computed: {
        cascaderPickerQueryParams() {
            return { activityType: this.formModel.activityType };
        },
    },
    methods: {
        onConfirm(isSubmit) {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.Activity.save({
                    ...this.formModel,
                    isSubmit,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Mb.Activity.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
